<template>
  <div class="vinculation">
    <div>
      Vincular:
    </div>
    <div class="form__radio--group">
      <input
        v-model="selected"
        class="form__radio collect__method--js"
        type="radio"
        id="vincule_entity"
        name="vinculation"
        value="entidade"
      />
      <label class="form__radio--label" for="vincule_entity">
        Entidade
      </label>
    </div>
    <div class="form__radio--group">
      <input
        v-model="selected"
        class="form__radio collect__method--js"
        type="radio"
        id="vincule_generator"
        name="vinculation"
        value="gerador-ponto"
      />
      <label class="form__radio--label" for="vincule_generator">
        Gerador
      </label>
    </div>
    <div class="form__radio--group">
      <input
        v-model="selected"
        class="form__radio collect__method--js"
        type="radio"
        id="vincule_collector_point"
        name="vinculation"
        value="coletor-point"
      />
      <label class="form__radio--label" for="vincule_collector_point">
        Ponto
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: { value: { type: [Number, String], required: true } },
  computed: {
    selected: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vinculation {
  display: flex;
  justify-content: space-between;
}
.vinculation div:first-child {
  font-weight: bold;
}
</style>
